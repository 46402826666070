.osago__banner {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
}

.osago__banner__description {
    background: linear-gradient(180deg, rgb(0, 126, 173) 0%, rgb(0, 198, 167) 100%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
    flex-direction: column;
    padding-bottom: 2rem;
}

.osago__banner__background {
    width: 100%;
    height: 20rem;
    background: url("./assets/background.jpg") no-repeat center center;
    background-size: cover;
    order: 0;
}

.osago__banner__description__content {
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 13px;
    padding: 2rem;
    margin: 2rem;
    max-width: 35rem;
}

.osago__banner__title {
    color: white;
    font-size: 1.75em;
    font-weight: bold;
    line-height: 1em;
    margin: 0;
    text-transform: uppercase;
}

.osago__banner__list {
    padding: 0;
    list-style: none;
}

.osago__banner__list > li {
    color: white;
    font-size: 1.125em;
    text-transform: uppercase;
    display: flex;
    align-items: flex-end;
    margin: .33rem 0;
    font-weight: bold;
}

.osago__banner__list > li img {
    width: 2rem;
    margin-right: .5rem;
}

.osago__banner__description__content p {
    color: white;
    font-size: 1.125em;
    text-transform: uppercase;
    display: flex;
    align-items: flex-end;
    margin: .33rem 0;
    font-weight: bold;
}

.osago__block {
    margin-top: 4em;
    width: 100%;
}

.osago__block__title {
    color: rgb(0, 170, 144);
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1em;
    text-align: center;
}

.osago__columns {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.osago__columns--center {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.osago__column {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.osago__column__number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 170, 144);
    font-size: 2.25em;
    font-weight: bold;
    line-height: 2.5em;
    border: 1px solid rgb(0, 170, 144);
    border-radius: 50%;
    text-align: center;
    padding: .5rem;
    height: 3.75rem;
    width: 3.75rem;
}

.osago__block--grey {
    margin-top: 4em;
    background-color: rgb(243, 243, 243);
    padding: 4rem 1rem;
}

.osago__inner {
    width: 100%;
    margin: 0 auto;
    max-width: 75rem;
}

.osago__block__title--black {
    color: rgb(88, 89, 91);
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    padding: 0 2rem;
}

.mt-25 {
    margin-top: 2.5rem !important;
}

.osago__column__mark {
    width: 1.5rem;
}

.osago__column--225 {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.osago__column--30 {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.osago__column--225:not(:last-child), .osago__column--30:not(:last-child) {
    border-bottom: 2px solid rgba(196, 196, 196, .2);
}

.osago__column--full {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 1rem 2rem;
}

.osago__column--50 {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
}

.osago__column--50 img {
    width: 5em;
    height: 5em;
    margin-right: 1em;
}

.osago__column--50 span {
    color: rgb(0, 170, 144);
    line-height: 1.125em;
    font-size: 1em;
    font-weight: bold;
}

.osago__inner--59 {
    max-width: 59rem;
    width: 100%;
    margin: 0 auto;
}

.price {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.span {
    text-indent: 2rem;
}

.table-price tr {
    padding: .5rem;
}

.table-price tbody tr td:nth-child(3) {
    font-weight: bold;
}


.table-price td {
    padding: .5rem;
}

.osago__calc {
    background: linear-gradient(238deg, rgb(0, 170, 144) 18%, rgb(54, 138, 186) 94%);
    border-radius: 10px;
    border: 1px solid transparent;
    margin: 0 2rem;
}

.osago__calc__title {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
}

.osago__calc__text {
    margin: 4rem 2rem;
}

.osago__calc--grey {
    background: rgb(243, 243, 243);
    border: 1px solid rgb(243, 243, 243);
    margin: 1rem 0;
    padding: 1rem;
}

.osago__calc__title--grey {
    color: rgb(88, 89, 91);
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    line-height: 1em;
}

.delimiter {
    margin-top: 4rem;
    box-shadow: none;
    border: 1px solid rgba(196, 196, 196, 0.2);
}

.delimiter--mobile {
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid rgba(196, 196, 196, 0.2);
    width: 100%;
}

.osago__calc__block {
    padding: 2rem;
}

.osago__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(9, 1fr);
    grid-template-areas:
            "one"
            "two"
            "five"
            "three"
            "four"
            "six"
            "eight"
            "nine"
            "seven";
    grid-row-gap: 1.5rem;
}

.flex {
    display: flex;
    justify-content: space-between;
    padding: .1rem 0;
    align-items: center;
}

.flex .osago__form__group__label {
    align-self: flex-start;
}

.osago__form__group {
    display: flex;
    flex-direction: column;
}

.osago__form__group__label {
    color: rgb(88, 89, 91);
    font-weight: bold;
    font-size: .875em;
    margin-left: .1rem;
}

.two { grid-area: two; }

.four { grid-area: four; }

.six { grid-area: six; }

.seven { grid-area: seven; }

.five { grid-area: five; }

.one { grid-area: one; }

.three { grid-area: three; }

.eight { grid-area: eight; }

.nine { grid-area: nine; }

.osago__form__group__checkbox {
    display: block;
    position: relative;
    padding-left: 1.33rem;
    margin-bottom: .5rem;
    cursor: pointer;
    user-select: none;
}

.osago__form__group__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.osago__form__group__checkmark {
    position: absolute;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: white;
    border: 1px solid rgb(207, 207, 207);
    border-radius: .25em;
}

.osago__form__group__checkbox:hover input ~ .osago__form__group__checkmark {
    background: linear-gradient(238deg, rgb(0, 170, 144) 18%, rgb(54, 138, 186) 94%);
}

.osago__form__group__checkbox input:checked ~ .osago__form__group__checkmark {
    border-color: rgb(54, 138, 186);
}

.osago__form__group__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.osago__form__group__checkbox input:checked ~ .osago__form__group__checkmark:after {
    display: block;
}

.osago__form__group__checkbox .osago__form__group__checkmark:after {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: .66em;
    height: .6em;
    background: linear-gradient(238deg, rgb(0, 170, 144) 18%, rgb(54, 138, 186) 94%);
    border-radius: .125em;
}

.calc__result {
    text-align: center;
    margin-bottom: 4em;
}

.hidden {
    display: none !important;
}

@media (min-width: 62rem) {

    .osago__banner {
        height: 30rem;
        flex-direction: row;
    }

    .osago__banner__description {
        width: 55%;
        order: 0;
    }

    .osago__banner__background, .partneram__banner__background {
        width: 45%;
        height: 100%;
        order: 1;
    }

    .osago__block__title {
        font-size: 2.25em;
    }

    .osago__columns {
        justify-content: space-between;
        flex-direction: row;
    }

    .osago__column {
        width: 30%;
        padding: 1rem;
        align-items: flex-start;
    }

    .osago__column--225 {
        text-align: left;
        align-items: flex-start;
        width: 22.5%;
        padding-left: 1rem;
    }

    .osago__column--30 {
        width: calc(30% - 4rem);
        margin: 0 1rem;
        padding-left: 1rem;
        align-items: flex-start;
    }

    .osago__column--225:not(:first-child), .osago__column--full, .osago__column--30:not(:first-child) {
        border-left: 2px solid rgba(196, 196, 196, .2);
    }

    .osago__column--225:not(:last-child), .osago__column--full, .osago__column--30:not(:last-child) {
        border-bottom: none
    }

    .osago__calc {
        background: linear-gradient(238deg, rgb(0, 170, 144) 18%, rgb(54, 138, 186) 94%);
        border-radius: 10px;
        border: 1px solid transparent;
        margin: 0 auto;
    }

    .osago__calc__text {
        margin: 4rem 0;
    }

    .osago__column--50 {
        padding: 0;
    }

    .osago__column--full {
        width: calc(50% - 1rem);
        padding-left: 1rem;
        text-align: left;
        align-items: flex-start;
    }

    .osago__calc--grey {
        border-radius: 10px;
        margin: 4rem 0;
    }

    .osago__calc__block {
        border: 2px solid rgb(243, 243, 243);
        border-radius: 10px;
        margin: 4rem 0;
    }

    .osago__form {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
                "one two five"
                "three four six"
                "eight nine seven";
        grid-column-gap: 3rem;
        grid-row-gap: 2rem;
    }

    .osago__form__group {
        justify-content: space-between;
    }

    .osago__block__title--black {
        font-size: 1.5em;
    }

    .osago__columns--center {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }

}